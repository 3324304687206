import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// assets
import { EditOutlined, LogoutOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout, onClickAway }) => {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  // const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        "& .MuiListItemIcon-root": {
          minWidth: 32,
          color: theme.palette.grey[500],
        },
      }}
    >
      <ListItemButton
        // selected={selectedIndex === 0}
        // onClick={(event) => handleListItemClick(event, 0)}
        onClick={(event) => {
          navigate(`/${user.userRoles}/update-profile/${user.adminUserId}`);
          onClickAway(event, 0);
        }}
      >
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary="Edit Profile" />
      </ListItemButton>
      <ListItemButton
        // selected={selectedIndex === 2}
        onClick={handleLogout}
      >
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

export default ProfileTab;
