// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  DashboardOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  DashboardOutlined,
  UsergroupAddOutlined,
};

const pages = {
  id: "group-broker-admin",
  title: "Broker Admin",
  type: "group",
  role: "broker_admin",
  children: [
    {
      id: "author-dashboard",
      title: "Manage Broker Members",
      type: "item",
      url: "/broker_admin",
      icon: icons.UsergroupAddOutlined,
    },
    {
      id: "admin--manage-customers",
      title: "Manage Customers",
      type: "item",
      url: "/broker_admin/customer",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
    // {
    //   id: 'grade-selection',
    //   title: 'Add Question',
    //   type: 'item',
    //   url: '/broker_admin/grade-selection',
    //   icon: icons.ChromeOutlined
    // }
  ],
};

export default pages;
