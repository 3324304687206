import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { changeRole } from 'store/reducers/auth';
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { InteractionOutlined } from '@ant-design/icons';

const SettingTab = () => {
  const theme = useTheme();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedRole } = auth;

  const handleListItemClick = (event, role) => {
    dispatch(changeRole({ selectedRole: role }));
    navigate(`/${role}`);
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
      {auth.user.ROLES.map((r, i) => (
        <ListItemButton key={i} selected={selectedRole === r} onClick={(event) => handleListItemClick(event, r)}>
          <ListItemIcon>
            <InteractionOutlined />
          </ListItemIcon>
          <ListItemText sx={{ textTransform: 'capitalize' }} primary={r} />
        </ListItemButton>
      ))}
    </List>
  );
};

export default SettingTab;
