import PropTypes from "prop-types";
import { ListSubheader, List, Typography } from "@mui/material";
import NavItem from "./NavItem";

const NavGroup = ({ item, handleDrawerToggle, isMobileView }) => {
  const items = item.children.map((menuItem) => {
    switch (menuItem.type) {
      case "item":
        return (
          <NavItem
            key={menuItem.id}
            item={menuItem}
            handleDrawerToggle={handleDrawerToggle}
            isMobileView={isMobileView}
          />
        );
      default:
        return (
          <Typography
            key={menuItem.id}
            variant="h6"
            color="error"
            align="center"
          >
            Fix - Navigation Item
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        item.title && (
          <ListSubheader
            component="div"
            id={`nested-list-subheader-${item.id}`}
          >
            {item.title}
          </ListSubheader>
        )
      }
    >
      {items}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
  handleDrawerToggle: PropTypes.func,
  isMobileView: PropTypes.bool,
};

export default NavGroup;
