import PropTypes from "prop-types";
import Navigation from "./Navigation";
import SimpleBar from "components/third-party/SimpleBar";

const DrawerContent = ({ handleDrawerToggle, isMobileView }) => (
  <SimpleBar
    sx={{
      "& .simplebar-content": {
        display: "flex",
        flexDirection: "column",
      },
    }}
  >
    <Navigation
      handleDrawerToggle={handleDrawerToggle}
      isMobileView={isMobileView}
    />
  </SimpleBar>
);

DrawerContent.propTypes = {
  handleDrawerToggle: PropTypes.func,
  isMobileView: PropTypes.bool,
};

export default DrawerContent;
