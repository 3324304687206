import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import NavGroup from "./NavGroup";
import menuItem from "menu-items";

const Navigation = ({ handleDrawerToggle, isMobileView }) => {
  const { selectedRole } = useSelector((state) => state.auth);

  const navGroups = menuItem.items.map((item) => {
    if (selectedRole !== item.role) {
      return <React.Fragment key={item.id}></React.Fragment>;
    }

    switch (item.type) {
      case "group":
        return (
          <NavGroup
            key={item.id}
            item={item}
            handleDrawerToggle={handleDrawerToggle}
            isMobileView={isMobileView}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
