// project import
import brokerAdmin from './bokerAdmin';
import brokerMember from './brokerMember';
import admin from './admin';

const menuItems = {
  items: [brokerAdmin, brokerMember, admin]
};

export default menuItems;
