import {
  DashboardOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

const icons = {
  DashboardOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
};
const admin = {
  id: "group-admin",
  title: "Administrator",
  type: "group",
  role: "super_admin",
  children: [
    {
      id: "admin-dashboard",
      title: "Manage Super Admin",
      type: "item",
      url: "/super_admin",
      icon: icons.UserAddOutlined,
      breadcrumbs: false,
    },
    {
      id: "admin-organtization",
      title: "Manage Organization",
      type: "item",
      url: "/super_admin/organization",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
    // {
    //   id: "admin-broker-organtization",
    //   title: "Manage Brokers",
    //   type: "item",
    //   url: "/super_admin/broker",
    //   icon: icons.UsergroupAddOutlined,
    //   breadcrumbs: false,
    // },
    {
      id: "admin--manage-customers",
      title: "Manage Customers",
      type: "item",
      url: "/super_admin/customer",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
  ],
};

export default admin;
