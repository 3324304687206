import { DashboardOutlined } from "@ant-design/icons";

const icons = {
  DashboardOutlined,
};

const brokerMember = {
  id: "group-publisher",
  title: "Publisher",
  type: "group",
  role: "broker_member",
  children: [
    {
      id: "publisher-dashboard",
      title: "Dashboard",
      type: "item",
      url: "/broker_member",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
  ],
};

export default brokerMember;
