import React, { lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// project import
import api from "api";
import { setAuthToken } from "api/index";
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import MinimalLayout from "layout/MinimalLayout";
import { userLogin } from "store/reducers/auth";

const Redirect = Loadable(lazy(() => import("pages/Redirect")));
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));
const AuthRegister = Loadable(
  lazy(() => import("pages/authentication/Register"))
);
const UnAuthorized = Loadable(lazy(() => import("pages/UnAuthorized")));
const PageNotFound = Loadable(lazy(() => import("pages/PageNotFound")));
const PageNotFound404 = Loadable(lazy(() => import("pages/404PageNotFound")));
const AuthForgotPassword = Loadable(
  lazy(() => import("pages/authentication/ForgotPassword"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("pages/authentication/ResetPassword"))
);
const UpdateProfile = Loadable(
  lazy(() => import("pages/common/UpdateProfile"))
);
// render - broker admin pages
const BrokerAdminDashboard = Loadable(
  lazy(() => import("pages/BrokerAdmin/ManageBrokerMembers/Dashboard"))
);
const AddBrokerMember = Loadable(
  lazy(() => import("pages/BrokerAdmin/ManageBrokerMembers/AddBroker"))
);
const UpdateBrokerMember = Loadable(
  lazy(() => import("pages/BrokerAdmin/ManageBrokerMembers/UpdateBroker"))
);
const BrokerAdminCustomerDashboard = Loadable(
  lazy(() => import("pages/BrokerAdmin/ManageCustomers/Dashboard"))
);
const BrokerAdminAddCustomer = Loadable(
  lazy(() => import("pages/BrokerAdmin/ManageCustomers/AddCustomer"))
);
const BrokerAdminUpdateCustomer = Loadable(
  lazy(() => import("pages/BrokerAdmin/ManageCustomers/UpdateCustomer"))
);
// render - broker member pages
const BrokerMembrDashboard = Loadable(
  lazy(() => import("pages/BrokerMember/Dashboard"))
);
const BrokerMemberAddCustomer = Loadable(
  lazy(() => import("pages/BrokerMember/AddCustomer"))
);
const BrokerMemberUpdateCustomer = Loadable(
  lazy(() => import("pages/BrokerMember/UpdateCustomer"))
);
// render - admin pages
const AdminDashboard = Loadable(
  lazy(() => import("pages/admin/ManageSuperAdmin/Dashboard"))
);
const AddSuperAdmin = Loadable(
  lazy(() => import("pages/admin/ManageSuperAdmin/AddSuperAdmin"))
);
const UpdateSuperAdmin = Loadable(
  lazy(() => import("pages/admin/ManageSuperAdmin/UpdateSuperAdmin"))
);
const ManageOrganization = Loadable(
  lazy(() => import("pages/admin/ManageOrganization/Dashboard"))
);
const AddOrganization = Loadable(
  lazy(() => import("pages/admin/ManageOrganization/AddOrganization"))
);
const UpdateOrganization = Loadable(
  lazy(() => import("pages/admin/ManageOrganization/UpdateOrganization"))
);
const ManageBroker = Loadable(
  lazy(() => import("pages/admin/ManageBroker/Dashboard"))
);
const AddBroker = Loadable(
  lazy(() => import("pages/admin/ManageBroker/AddBroker"))
);
const UpdateBroker = Loadable(
  lazy(() => import("pages/admin/ManageBroker/UpdateBroker"))
);
const CustomerDashboard = Loadable(
  lazy(() => import("pages/admin/ManageCustomers/Dashboard"))
);
const AddCustomer = Loadable(
  lazy(() => import("pages/admin/ManageCustomers/AddCustomer"))
);
const UpdateCustomer = Loadable(
  lazy(() => import("pages/admin/ManageCustomers/UpdateCustomer"))
);
const AppRoutes = () => {
  const [loading, setLoading] = useState(true); // Initialize with loading state
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const userRoles = user ? user.userRoles : [];

  useEffect(() => {
    const getAuthUser = () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        setLoading(false);
        dispatch(
          userLogin({
            user: undefined,
          })
        );
        return;
      }

      setAuthToken(token);
      api
        .get("/api/auth")
        .then((response) => {
          setLoading(false);
          dispatch(
            userLogin({
              user: response.data,
            })
          );
        })
        .catch((error) => {
          setLoading(false);
          dispatch(
            userLogin({
              user: undefined,
            })
          );
        });
    };

    getAuthUser();
  }, [dispatch]);

  const isSuperAdmin = userRoles === "super_admin";
  const isBrokerAdmin = userRoles === "broker_admin";
  const isBrokerMember = userRoles === "broker_member";

  return (
    <>
      {!loading ? (
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<MinimalLayout />}>
            <Route path="" element={<Redirect />} />
            <Route path="login" element={<AuthLogin />} />
            <Route path="register" element={<AuthRegister />} />
            <Route path="forgot-password" element={<AuthForgotPassword />} />
            <Route path="404" element={<PageNotFound404 />} />
            <Route
              path="reset-password/:token"
              element={<AuthResetPassword />}
            />
          </Route>

          {/* Broker Admin routes */}
          {(isBrokerAdmin || isSuperAdmin) && (
            <Route path="/broker_admin/*" element={<MainLayout />}>
              <Route path="" element={<BrokerAdminDashboard />} />
              <Route path="add-broker" element={<AddBrokerMember />} />
              <Route
                path="broker/edit-broker/:id"
                element={<UpdateBrokerMember />}
              />
              <Route path="update-profile/:id" element={<UpdateProfile />} />
              <Route
                path="customer"
                element={<BrokerAdminCustomerDashboard />}
              />
              <Route
                path="customer/add-customer"
                element={<BrokerAdminAddCustomer />}
              />
              <Route
                path="customer/update-customer/:id"
                element={<BrokerAdminUpdateCustomer />}
              />
            </Route>
          )}

          {/* Broker Member routes */}
          {(isBrokerMember || isSuperAdmin) && (
            <Route path="/broker_member/*" element={<MainLayout />}>
              <Route path="" element={<BrokerMembrDashboard />} />
              <Route
                path="customer/add-customer"
                element={<BrokerMemberAddCustomer />}
              />
              <Route
                path="customer/update-customer/:id"
                element={<BrokerMemberUpdateCustomer />}
              />
              <Route path="update-profile/:id" element={<UpdateProfile />} />
            </Route>
          )}

          {/* Admin routes */}
          {isSuperAdmin && (
            <Route path="/super_admin/*" element={<MainLayout />}>
              <Route path="broker/:id" element={<ManageBroker />} />
              <Route path="broker/add-broker/:id" element={<AddBroker />} />
              <Route path="add-superadmin" element={<AddSuperAdmin />} />
              <Route
                path="edit-superadmin/:id"
                element={<UpdateSuperAdmin />}
              />
              <Route path="broker/edit-broker/:id" element={<UpdateBroker />} />
              <Route path="organization" element={<ManageOrganization />} />
              <Route
                path="organization/add-organization"
                element={<AddOrganization />}
              />
              <Route
                path="organization/edit-organization/:id"
                element={<UpdateOrganization />}
              />
              <Route path="update-profile/:id" element={<UpdateProfile />} />
              <Route path="customer" element={<CustomerDashboard />} />
              <Route path="customer/add-customer" element={<AddCustomer />} />
              <Route
                path="customer/update-customer/:id"
                element={<UpdateCustomer />}
              />
              <Route path="" element={<AdminDashboard />} />
            </Route>
          )}

          {(isBrokerAdmin || isSuperAdmin || isBrokerMember) && (
            <Route path="/*" element={<MainLayout />}>
              <Route path="*" element={<UnAuthorized />} />
            </Route>
          )}

          {/* Fallback route */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      ) : (
        ""
      )}
    </>
  );
};

export default AppRoutes;
