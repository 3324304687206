// import axios from "axios";
// // Create an Axios instance with default configurations
// const axiosInstance = axios.create({
//   baseURL: "/", // Replace with your API URL
//   withCredentials: true, // Set this to true to include credentials
// });

// export default axiosInstance;
import axios from "axios";
// Create an Axios instance with default configurations
const axiosInstance = axios?.create({
  baseURL:
    window.location.host === "localhost:3000" ? "http://localhost:5000" : "/", // Replace with your API URL

  withCredentials: true, // Set this to true to include credentials
});
axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === "Token is not valid") {
      console.log("Need to logout user");
    }
    return Promise.reject(err);
  }
);
export const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = token;

    localStorage.setItem("token", token);
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export default axiosInstance;
