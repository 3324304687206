// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  user: null,
  selectedRole: null
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLogin(state, action) {
      state.user = action.payload.user;
      state.selectedRole = action.payload.user?.userRoles;
      localStorage.setItem('lastLogin', new Date().toISOString());
    },

    userLogout(state) {
      state.user = null;
      state.selectedRole = null;
      localStorage.removeItem('lastLogin');
    },

    changeRole(state, action) {
      if (state.selectedRole !== action.payload.selectedRole) {
        state.selectedRole = action.payload.selectedRole;
      }
    }
  }
});

export default user.reducer;

export const { userLogin, userLogout, changeRole } = user.actions;
